import { FC, ChangeEvent, useState } from 'react';
import TextField from '@polydice/ui/dist/TextField';
import Select from '@polydice/ui/dist/Select';
import HelperText from '@polydice/ui/dist/HelperText';
import Button from '@polydice/ui/dist/Button';
import {
  PHONE_REGEX,
  PHONE_ERROR_REQUIRED,
  PHONE_ERROR_INVALID,
  COUNTRY_LIST
} from './constants';
import cx from 'classnames';
import styles from './style.module.scss';
import deviseStyles from '../Devise/style.module.scss';

interface PhoneValidationFormProps {
  popup: boolean;
  countryCode: string;
  setCountryCode: (code: string) => void;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  isLoading: boolean;
  onBack: () => void;
  onSubmit: () => void;
}

const PhoneValidationForm: FC<PhoneValidationFormProps> = ({
  popup,
  countryCode,
  setCountryCode,
  phoneNumber,
  setPhoneNumber,
  isLoading,
  onBack,
  onSubmit
}) => {
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState('');

  const getCountryOptions = () => {
    return COUNTRY_LIST.map((country) => ({
      value: country.code,
      label: `${country.name} (+${country.code.split('-')[0]})`
    }));
  };

  const onCountryChange = (value: string) => {
    setCountryCode(value);
  };

  const onPhoneNumberChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(value);
    if (!value) {
      setPhoneNumberHelperText(PHONE_ERROR_REQUIRED);
    } else if (!PHONE_REGEX.test(value)) {
      setPhoneNumberHelperText(PHONE_ERROR_INVALID);
    } else {
      setPhoneNumberHelperText('');
    }
  };

  const disableButton =
    phoneNumber.length === 0 || phoneNumberHelperText.length > 0 || isLoading;

  return (
    <div className={styles.phoneValidationForm}>
      <div className={deviseStyles.signInHeader}>
        <button
          className={deviseStyles.signInHeaderReturnBtn}
          onClick={onBack}
          type="button"
        >
          {popup ? (
            <span>
              <i className="icon-times-regular"></i>
            </span>
          ) : (
            <span>
              <i className="icon-chevron-left-regular"></i>返回
            </span>
          )}
        </button>
        <h1>使用手機號碼驗證</h1>
        <div className={deviseStyles.signInHeaderEmpty}></div>
      </div>
      <div>
        <Select
          className={deviseStyles.deviseMainSelect}
          name="country-code"
          label="國家"
          options={getCountryOptions()}
          value={countryCode}
          onChange={onCountryChange}
        />
        <TextField
          className={deviseStyles.deviseMainTextField}
          label="手機號碼"
          type="phoneNumber"
          inputMode="numeric"
          name="current-phoneNumber"
          pattern={PHONE_REGEX.source}
          required
          autoComplete="current-phoneNumber"
          value={phoneNumber}
          onChange={onPhoneNumberChange}
          onBlur={onPhoneNumberChange}
        />
        <HelperText
          className={deviseStyles.deviseMainHelperText}
          text={phoneNumberHelperText}
        />
        <Button
          id="phone-validation-submit"
          className={cx(deviseStyles.signInMainBtn, {
            [styles.signInMainBtnMock]: disableButton
          })}
          styleType="contained"
          size="lg"
          color="primary"
          pending={isLoading}
          unfulfilled={disableButton}
          onClick={onSubmit}
        >
          繼續
        </Button>
      </div>
      <div className={deviseStyles.signInFooter}>
        需要幫忙嗎？
        <a
          href="https://help.icook.tw/hc/zh-tw/requests/new"
          rel="nofollow noopener"
        >
          聯絡我們
        </a>
      </div>
    </div>
  );
};

export default PhoneValidationForm;
