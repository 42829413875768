import { useState, FC } from 'react';
import PhoneValidationForm from './PhoneValidationForm';
import PhoneOTPVerify from './PhoneOTPVerify';
import styles from './style.module.scss';
import { captureException } from '@sentry/browser';
import { getAuthToken } from 'reactjs/utils';
import firebase from 'firebase/app';
import 'firebase/auth';

interface PhoneValidationProps {
  popup: boolean;
  onClose: () => void;
  onOtpSubmit: (
    phone: string,
    phone_token: string,
    otp: string
  ) => Promise<any>;
  onFirebaseSuccess: (token: string, user: firebase.User) => void;
  onFail: (error: string) => void;
}

const PhoneValidation: FC<PhoneValidationProps> = ({
  popup,
  onClose,
  onOtpSubmit,
  onFirebaseSuccess,
  onFail
}) => {
  const [countryCode, setCountryCode] = useState('886-TW');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneToken, setPhoneToken] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [resendSeconds, setResendSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmation, setConfirmation] =
    useState<firebase.auth.ConfirmationResult | null>(null);
  const [appVerifier, setAppVerifier] =
    useState<firebase.auth.RecaptchaVerifier | null>(null);

  const phoneSubmit = async () => {
    setIsLoading(true);

    const code = countryCode.split('-')[0];
    let phone = phoneNumber;
    if (phone.startsWith('0')) {
      phone = `+${code}${phone.substring(1)}`;
    } else if (phone.startsWith(code)) {
      phone = `+${phone}`;
    } else if (!phone.startsWith('+')) {
      phone = `+${code}${phone}`;
    }
    setPhone(phone);

    if (code === '886') {
      try {
        const response = await fetch('/api/v1/accounts/sms', {
          headers: {
            'X-CSRF-Token': getAuthToken(),
            'Content-Type': 'application/json'
          },
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify({
            phone,
            name: 'web'
          })
        });

        if (response.ok) {
          const { phone_token } = await response.json();
          setPhoneToken(phone_token);
          setResendSeconds(180);
        } else {
          const { error } = await response.json();
          setPhoneToken('');
          setResendSeconds(0);
          onFail(error);
        }
      } catch (error) {
        captureException(error);
        setPhoneToken('');
        setResendSeconds(0);
      }
    } else {
      const {
        firebaseApp
      }: {
        firebaseApp: firebase.app.App;
      } = require('components/firebase');

      let verifier: firebase.auth.RecaptchaVerifier;
      if (appVerifier) {
        verifier = appVerifier;
      } else {
        verifier = new firebase.auth.RecaptchaVerifier(
          'phone-validation-submit',
          { size: 'invisible' }
        );
        setAppVerifier(verifier);
      }
      firebaseApp
        .auth()
        .signInWithPhoneNumber(phone, verifier)
        .then((result: firebase.auth.ConfirmationResult) => {
          setConfirmation(result);
        })
        .catch((error: Error) => {
          captureException(error);
          onFail(error.message);
        });
    }

    setIsLoading(false);
  };

  const otpSubmit = async () => {
    if (isLoading) {
      return;
    }
    if (phoneToken) {
      setIsLoading(true);
      const error = await onOtpSubmit(phone, phoneToken, otp);
      if (error) {
        setIsLoading(false);
        setOtp('');
        onFail(error);
      }
    } else if (confirmation) {
      setIsLoading(true);
      confirmation
        .confirm(otp)
        .then((result) => {
          const user = result.user;
          if (user) {
            user.getIdToken(true).then((idToken: string) => {
              onFirebaseSuccess(idToken, user);
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setConfirmation(null);
          onFail(error);
        });
    }
  };

  return (
    <div
      className={popup ? styles.phoneValidationPopup : styles.phoneValidation}
    >
      {phoneToken || confirmation ? (
        <PhoneOTPVerify
          {...{
            phone,
            otp,
            setOtp,
            resendSeconds,
            setResendSeconds,
            isLoading,
            onBack: () => {
              setOtp('');
              setPhoneToken('');
              setConfirmation(null);
            },
            onSubmit: otpSubmit,
            onResend: phoneSubmit
          }}
        />
      ) : (
        <PhoneValidationForm
          {...{
            popup,
            countryCode,
            setCountryCode,
            phoneNumber,
            setPhoneNumber,
            isLoading,
            onBack: onClose,
            onSubmit: phoneSubmit
          }}
        />
      )}
    </div>
  );
};

export default PhoneValidation;
