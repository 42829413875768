import { FC, useEffect, ChangeEvent, useRef } from 'react';
import Progress from '@polydice/ui/dist/Progress';
import cx from 'classnames';
import styles from './style.module.scss';
import deviseStyles from '../Devise/style.module.scss';

interface PhoneOTPVerifyProps {
  phone: string;
  otp: string;
  setOtp: (phone: string) => void;
  resendSeconds: number;
  setResendSeconds: (seconds: number) => void;
  isLoading: boolean;
  onBack: () => void;
  onSubmit: () => void;
  onResend: () => void;
}

const PhoneOTPVerify: FC<PhoneOTPVerifyProps> = ({
  phone,
  otp,
  setOtp,
  resendSeconds,
  setResendSeconds,
  isLoading,
  onBack,
  onSubmit,
  onResend
}) => {
  const otpInputRef = useRef<HTMLInputElement>(null);

  const onOtpChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    const otp = value.replace(/[^0-9]/g, '').slice(0, 6);
    setOtp(otp);
  };

  useEffect(() => {
    if (otp.length === 0) {
      otpInputRef.current?.focus();
    } else if (otp.length === 6) {
      onSubmit();
    }
  }, [otp, onSubmit]);

  const resendRemainProgress = (180 - resendSeconds) / 180 / 0.01;
  const resendButtonDisabled = resendSeconds > 0 || isLoading;

  useEffect(() => {
    if (resendSeconds > 0) {
      const timer = setTimeout(() => {
        setResendSeconds(resendSeconds - 1);
        if (resendSeconds === 179) {
          otpInputRef.current?.focus();
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [resendSeconds, setResendSeconds]);

  useEffect(() => {
    setTimeout(() => otpInputRef.current?.focus(), 25);
  }, []);

  return (
    <div className={styles.phoneValidationForm}>
      <div className={deviseStyles.signInHeader}>
        <button
          className={deviseStyles.signInHeaderReturnBtn}
          onClick={onBack}
          type="button"
        >
          <i className="icon-chevron-left-regular"></i>
          <span>返回</span>
        </button>
        <h1>輸入驗證碼</h1>
        <div className={deviseStyles.signInHeaderEmpty}></div>
      </div>
      <div className={styles.otpDescription}>
        我們已經把驗證碼發送到：{phone}
      </div>
      {isLoading && (
        <div className={styles.otpLoading}>
          <Progress />
        </div>
      )}
      {!isLoading && (
        <>
          <div className={styles.otpInput}>
            <div className={styles.otpInputBox}>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
            </div>
            <input
              className={`${styles.otpInputText}`}
              type="number"
              name="code"
              ref={otpInputRef}
              value={otp}
              onChange={onOtpChange}
              disabled={isLoading}
            />
          </div>
          <button
            className={cx(deviseStyles.magicLinkVerifyProgressBtn, {
              [deviseStyles.magicLinkVerifyProgressBtnDisabled]:
                resendButtonDisabled
            })}
            disabled={resendButtonDisabled}
            onClick={onResend}
          >
            <div
              className={deviseStyles.magicLinkVerifyProgressBtnFront}
              style={{
                clipPath: `inset(0 0 0 ${resendRemainProgress}%)`
              }}
            >
              重新發送驗證碼 {resendSeconds > 0 && `${resendSeconds}s`}
            </div>
            <div className={deviseStyles.magicLinkVerifyProgressBtnBack}>
              重新發送驗證碼 {resendSeconds > 0 && `${resendSeconds}s`}
            </div>
          </button>
        </>
      )}
      <div className={deviseStyles.signInFooter}>
        需要幫忙嗎？
        <a
          href="https://help.icook.tw/hc/zh-tw/requests/new"
          rel="nofollow noopener"
        >
          聯絡我們
        </a>
      </div>
    </div>
  );
};

export default PhoneOTPVerify;
