export const PHONE_REGEX = /^\+?\d+$/;

export const PHONE_ERROR_REQUIRED = '請輸入手機號碼';

export const PHONE_ERROR_INVALID = '手機號碼格式錯誤';

export const COUNTRY_LIST = [
  {
    code: '886-TW',
    name: '台灣'
  },
  {
    code: '1-TT',
    name: '千里達/托巴哥'
  },
  {
    code: '90-TR',
    name: '土耳其'
  },
  {
    code: '993-TM',
    name: '土庫曼'
  },
  {
    code: '975-BT',
    name: '不丹'
  },
  {
    code: '236-CF',
    name: '中非共和國'
  },
  {
    code: '86-CN',
    name: '中國'
  },
  {
    code: '45-DK',
    name: '丹麥'
  },
  {
    code: '593-EC',
    name: '厄瓜多'
  },
  {
    code: '291-ER',
    name: '厄利垂亞'
  },
  {
    code: '675-PG',
    name: '巴布亞紐幾內亞'
  },
  {
    code: '55-BR',
    name: '巴西'
  },
  {
    code: '1-BB',
    name: '巴貝多'
  },
  {
    code: '595-PY',
    name: '巴拉圭'
  },
  {
    code: '973-BH',
    name: '巴林'
  },
  {
    code: '1-BS',
    name: '巴哈馬'
  },
  {
    code: '507-PA',
    name: '巴拿馬'
  },
  {
    code: '970-PS',
    name: '巴勒斯坦自治區'
  },
  {
    code: '92-PK',
    name: '巴基斯坦'
  },
  {
    code: '81-JP',
    name: '日本'
  },
  {
    code: '32-BE',
    name: '比利時'
  },
  {
    code: '1-JM',
    name: '牙買加'
  },
  {
    code: '972-IL',
    name: '以色列'
  },
  {
    code: '1-CA',
    name: '加拿大'
  },
  {
    code: '241-GA',
    name: '加彭'
  },
  {
    code: '1-MP',
    name: '北馬里亞納群島'
  },
  {
    code: '850-KP',
    name: '北韓'
  },
  {
    code: '974-QA',
    name: '卡達'
  },
  {
    code: '53-CU',
    name: '古巴'
  },
  {
    code: '599-CW',
    name: '古拉索'
  },
  {
    code: '268-SZ',
    name: '史瓦濟蘭'
  },
  {
    code: '227-NE',
    name: '尼日'
  },
  {
    code: '505-NI',
    name: '尼加拉瓜'
  },
  {
    code: '977-NP',
    name: '尼泊爾'
  },
  {
    code: '226-BF',
    name: '布吉納法索'
  },
  {
    code: '590-GP',
    name: '瓜地洛普'
  },
  {
    code: '502-GT',
    name: '瓜地馬拉'
  },
  {
    code: '681-WF',
    name: '瓦利斯及富圖納群島'
  },
  {
    code: '220-GM',
    name: '甘比亞'
  },
  {
    code: '375-BY',
    name: '白俄羅斯'
  },
  {
    code: '370-LT',
    name: '立陶宛'
  },
  {
    code: '964-IQ',
    name: '伊拉克'
  },
  {
    code: '98-IR',
    name: '伊朗'
  },
  {
    code: '354-IS',
    name: '冰島'
  },
  {
    code: '423-LI',
    name: '列支敦斯登'
  },
  {
    code: '36-HU',
    name: '匈牙利'
  },
  {
    code: '62-ID',
    name: '印尼'
  },
  {
    code: '91-IN',
    name: '印度'
  },
  {
    code: '253-DJ',
    name: '吉布地'
  },
  {
    code: '686-KI',
    name: '吉里巴斯'
  },
  {
    code: '996-KG',
    name: '吉爾吉斯'
  },
  {
    code: '688-TV',
    name: '吐瓦魯'
  },
  {
    code: '1-DM',
    name: '多米尼克'
  },
  {
    code: '1-DO',
    name: '多明尼加共和國'
  },
  {
    code: '228-TG',
    name: '多哥'
  },
  {
    code: '1-AI',
    name: '安吉拉'
  },
  {
    code: '1-AG',
    name: '安地卡及巴布達'
  },
  {
    code: '244-AO',
    name: '安哥拉'
  },
  {
    code: '376-AD',
    name: '安道爾共和國'
  },
  {
    code: '690-TK',
    name: '托克勞群島'
  },
  {
    code: '1-BM',
    name: '百慕達'
  },
  {
    code: '251-ET',
    name: '衣索比亞'
  },
  {
    code: '34-ES',
    name: '西班牙'
  },
  {
    code: '212-EH',
    name: '西撒哈拉'
  },
  {
    code: '385-HR',
    name: '克羅埃西亞'
  },
  {
    code: '47-SJ',
    name: '冷岸及央棉群島'
  },
  {
    code: '218-LY',
    name: '利比亞'
  },
  {
    code: '504-HN',
    name: '宏都拉斯'
  },
  {
    code: '30-GR',
    name: '希臘'
  },
  {
    code: '673-BN',
    name: '汶萊'
  },
  {
    code: '966-SA',
    name: '沙烏地阿拉伯'
  },
  {
    code: '501-BZ',
    name: '貝里斯'
  },
  {
    code: '229-BJ',
    name: '貝南'
  },
  {
    code: '240-GQ',
    name: '赤道幾內亞'
  },
  {
    code: '263-ZW',
    name: '辛巴威'
  },
  {
    code: '264-NA',
    name: '那米比亞'
  },
  {
    code: '374-AM',
    name: '亞美尼亞'
  },
  {
    code: '247-AC',
    name: '亞森松島'
  },
  {
    code: '994-AZ',
    name: '亞塞拜然'
  },
  {
    code: '255-TZ',
    name: '坦尚尼亞'
  },
  {
    code: '234-NG',
    name: '奈及利亞'
  },
  {
    code: '58-VE',
    name: '委內瑞拉'
  },
  {
    code: '880-BD',
    name: '孟加拉'
  },
  {
    code: '260-ZM',
    name: '尚比亞'
  },
  {
    code: '680-PW',
    name: '帛琉'
  },
  {
    code: '677-SB',
    name: '所羅門群島'
  },
  {
    code: '371-LV',
    name: '拉脫維亞'
  },
  {
    code: '676-TO',
    name: '東加王國'
  },
  {
    code: '670-TL',
    name: '東帝汶'
  },
  {
    code: '33-FR',
    name: '法國'
  },
  {
    code: '298-FO',
    name: '法羅群島'
  },
  {
    code: '594-GF',
    name: '法屬圭亞那'
  },
  {
    code: '689-PF',
    name: '法屬玻里尼西亞群島'
  },
  {
    code: '590-MF',
    name: '法屬聖馬丁'
  },
  {
    code: '387-BA',
    name: '波士尼亞與赫塞哥維納'
  },
  {
    code: '267-BW',
    name: '波札那'
  },
  {
    code: '1-PR',
    name: '波多黎克'
  },
  {
    code: '48-PL',
    name: '波蘭'
  },
  {
    code: '350-GI',
    name: '直布羅陀'
  },
  {
    code: '254-KE',
    name: '肯亞'
  },
  {
    code: '358-FI',
    name: '芬蘭'
  },
  {
    code: '971-AE',
    name: '阿拉伯聯合大公國'
  },
  {
    code: '54-AR',
    name: '阿根廷'
  },
  {
    code: '968-OM',
    name: '阿曼'
  },
  {
    code: '93-AF',
    name: '阿富汗'
  },
  {
    code: '213-DZ',
    name: '阿爾及利亞'
  },
  {
    code: '355-AL',
    name: '阿爾巴尼亞'
  },
  {
    code: '297-AW',
    name: '阿魯巴島'
  },
  {
    code: '7-RU',
    name: '俄羅斯'
  },
  {
    code: '359-BG',
    name: '保加利亞'
  },
  {
    code: '27-ZA',
    name: '南非'
  },
  {
    code: '500-GS',
    name: '南喬治亞及南桑威奇群島'
  },
  {
    code: '82-KR',
    name: '南韓'
  },
  {
    code: '211-SS',
    name: '南蘇丹'
  },
  {
    code: '7-KZ',
    name: '哈薩克'
  },
  {
    code: '235-TD',
    name: '查德'
  },
  {
    code: '855-KH',
    name: '柬埔寨'
  },
  {
    code: '591-BO',
    name: '玻利維亞'
  },
  {
    code: '965-KW',
    name: '科威特'
  },
  {
    code: '61-CC',
    name: '科科斯群島'
  },
  {
    code: '377-XK',
    name: '科索沃'
  },
  {
    code: '381-XK',
    name: '科索沃'
  },
  {
    code: '386-XK',
    name: '科索沃'
  },
  {
    code: '216-TN',
    name: '突尼西亞'
  },
  {
    code: '962-JO',
    name: '約旦'
  },
  {
    code: '1-US',
    name: '美國'
  },
  {
    code: '1-VI',
    name: '美屬維京群島'
  },
  {
    code: '1-AS',
    name: '美屬薩摩亞'
  },
  {
    code: '44-GB',
    name: '英國'
  },
  {
    code: '246-IO',
    name: '英屬印度洋領地'
  },
  {
    code: '1-VG',
    name: '英屬維京群島'
  },
  {
    code: '222-MR',
    name: '茅利塔尼亞'
  },
  {
    code: '233-GH',
    name: '迦納'
  },
  {
    code: '852-HK',
    name: '香港'
  },
  {
    code: '243-CD',
    name: '剛果民主共和國'
  },
  {
    code: '242-CG',
    name: '剛果共和國'
  },
  {
    code: '57-CO',
    name: '哥倫比亞'
  },
  {
    code: '506-CR',
    name: '哥斯大黎加'
  },
  {
    code: '20-EG',
    name: '埃及'
  },
  {
    code: '682-CK',
    name: '庫克群島'
  },
  {
    code: '47-NO',
    name: '挪威'
  },
  {
    code: '44-GG',
    name: '根西島'
  },
  {
    code: '299-GL',
    name: '格陵蘭'
  },
  {
    code: '1-GD',
    name: '格瑞那達'
  },
  {
    code: '66-TH',
    name: '泰國'
  },
  {
    code: '509-HT',
    name: '海地'
  },
  {
    code: '256-UG',
    name: '烏干達'
  },
  {
    code: '380-UA',
    name: '烏克蘭文'
  },
  {
    code: '598-UY',
    name: '烏拉圭'
  },
  {
    code: '998-UZ',
    name: '烏茲別克'
  },
  {
    code: '1-TC',
    name: '特克斯和凱科斯群島'
  },
  {
    code: '262-RE',
    name: '留尼旺'
  },
  {
    code: '51-PE',
    name: '祕魯'
  },
  {
    code: '64-NZ',
    name: '紐西蘭'
  },
  {
    code: '683-NU',
    name: '紐埃島'
  },
  {
    code: '252-SO',
    name: '索馬利亞'
  },
  {
    code: '596-MQ',
    name: '馬丁尼克島'
  },
  {
    code: '223-ML',
    name: '馬利'
  },
  {
    code: '60-MY',
    name: '馬來西亞'
  },
  {
    code: '389-MK',
    name: '馬其頓'
  },
  {
    code: '265-MW',
    name: '馬拉威'
  },
  {
    code: '262-YT',
    name: '馬約特'
  },
  {
    code: '692-MH',
    name: '馬歇爾島'
  },
  {
    code: '261-MG',
    name: '馬達加斯加'
  },
  {
    code: '356-MT',
    name: '馬爾他'
  },
  {
    code: '960-MV',
    name: '馬爾地夫'
  },
  {
    code: '691-FM',
    name: '密克羅尼西亞'
  },
  {
    code: '420-CZ',
    name: '捷克共和國'
  },
  {
    code: '963-SY',
    name: '敘利亞'
  },
  {
    code: '44-IM',
    name: '曼島'
  },
  {
    code: '379-VA',
    name: '梵蒂岡'
  },
  {
    code: '1-SX',
    name: '荷屬聖馬丁'
  },
  {
    code: '31-NL',
    name: '荷蘭'
  },
  {
    code: '599-BQ',
    name: '荷蘭加勒比海屬地'
  },
  {
    code: '258-MZ',
    name: '莫三比克'
  },
  {
    code: '237-CM',
    name: '喀麥隆'
  },
  {
    code: '995-GE',
    name: '喬治亞'
  },
  {
    code: '245-GW',
    name: '幾內亞比索'
  },
  {
    code: '224-GN',
    name: '幾內亞柯那克里'
  },
  {
    code: '679-FJ',
    name: '斐濟'
  },
  {
    code: '94-LK',
    name: '斯里蘭卡'
  },
  {
    code: '421-SK',
    name: '斯洛伐克'
  },
  {
    code: '386-SI',
    name: '斯洛維尼亞'
  },
  {
    code: '56-CL',
    name: '智利'
  },
  {
    code: '63-PH',
    name: '菲律賓'
  },
  {
    code: '225-CI',
    name: '象牙海岸'
  },
  {
    code: '84-VN',
    name: '越南'
  },
  {
    code: '1-KY',
    name: '開曼群島'
  },
  {
    code: '992-TJ',
    name: '塔吉克'
  },
  {
    code: '221-SN',
    name: '塞內加爾'
  },
  {
    code: '248-SC',
    name: '塞席爾'
  },
  {
    code: '43-AT',
    name: '奧地利'
  },
  {
    code: '358-AX',
    name: '奧蘭群島'
  },
  {
    code: '372-EE',
    name: '愛沙尼亞'
  },
  {
    code: '353-IE',
    name: '愛爾蘭'
  },
  {
    code: '65-SG',
    name: '新加坡'
  },
  {
    code: '687-NC',
    name: '新喀里多尼亞'
  },
  {
    code: '232-SL',
    name: '獅子山'
  },
  {
    code: '41-CH',
    name: '瑞士'
  },
  {
    code: '46-SE',
    name: '瑞典'
  },
  {
    code: '381-RS',
    name: '瑟比雅'
  },
  {
    code: '678-VU',
    name: '萬那杜'
  },
  {
    code: '39-IT',
    name: '義大利'
  },
  {
    code: '590-BL',
    name: '聖巴瑟米'
  },
  {
    code: '1-VC',
    name: '聖文森'
  },
  {
    code: '508-PM',
    name: '聖皮埃與密克隆群島'
  },
  {
    code: '239-ST',
    name: '聖多美普林西比'
  },
  {
    code: '1-KN',
    name: '聖克里斯多福'
  },
  {
    code: '290-SH',
    name: '聖海蓮娜'
  },
  {
    code: '378-SM',
    name: '聖馬利諾'
  },
  {
    code: '61-CX',
    name: '聖誕島'
  },
  {
    code: '1-LC',
    name: '聖露西亞'
  },
  {
    code: '967-YE',
    name: '葉門'
  },
  {
    code: '269-KM',
    name: '葛摩'
  },
  {
    code: '351-PT',
    name: '葡萄牙'
  },
  {
    code: '500-FK',
    name: '福克蘭群島 [馬爾維納斯群島]'
  },
  {
    code: '238-CV',
    name: '維德角'
  },
  {
    code: '976-MN',
    name: '蒙古'
  },
  {
    code: '1-MS',
    name: '蒙哲臘'
  },
  {
    code: '382-ME',
    name: '蒙特內哥羅'
  },
  {
    code: '257-BI',
    name: '蒲隆地'
  },
  {
    code: '592-GY',
    name: '蓋亞那'
  },
  {
    code: '672-HM',
    name: '赫德島與麥克唐納群島'
  },
  {
    code: '856-LA',
    name: '寮國'
  },
  {
    code: '49-DE',
    name: '德國'
  },
  {
    code: '212-MA',
    name: '摩洛哥'
  },
  {
    code: '377-MC',
    name: '摩納哥'
  },
  {
    code: '373-MD',
    name: '摩爾多瓦'
  },
  {
    code: '230-MU',
    name: '模里西斯'
  },
  {
    code: '95-MM',
    name: '緬甸'
  },
  {
    code: '961-LB',
    name: '黎巴嫩'
  },
  {
    code: '52-MX',
    name: '墨西哥'
  },
  {
    code: '44-JE',
    name: '澤西島'
  },
  {
    code: '853-MO',
    name: '澳門特別行政區'
  },
  {
    code: '61-AU',
    name: '澳洲'
  },
  {
    code: '250-RW',
    name: '盧安達'
  },
  {
    code: '352-LU',
    name: '盧森堡'
  },
  {
    code: '672-NF',
    name: '諾福克島'
  },
  {
    code: '674-NR',
    name: '諾魯'
  },
  {
    code: '231-LR',
    name: '賴比瑞亞'
  },
  {
    code: '266-LS',
    name: '賴索托'
  },
  {
    code: '357-CY',
    name: '賽普勒斯'
  },
  {
    code: '503-SV',
    name: '薩爾瓦多共和國'
  },
  {
    code: '685-WS',
    name: '薩摩亞'
  },
  {
    code: '40-RO',
    name: '羅馬尼亞'
  },
  {
    code: '1-GU',
    name: '關島'
  },
  {
    code: '249-SD',
    name: '蘇丹'
  },
  {
    code: '597-SR',
    name: '蘇利南'
  }
];
